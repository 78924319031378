import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";

function About() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={ { marginTop: "100px", maxWidth: "880px", lineHeight: 2 } }>
          {/* <Headings.h1>About</Headings.h1> */ }
          <Headings.h2>About</Headings.h2>
          {/* <p>Exploring cutting edge research and startups.</p> */ }
          <p style={ { marginTop: "10px", fontSize: "1.8rem" } }>BTBB covers innovation in ecommerce, supply chain and manufacturing, research and technology. Get in touch at <a href="mailto:hello@behindthebuybutton.com">hello@behindthebuybutton.com</a>.</p>
          {/* <p style={ { marginTop: "10px", fontSize: "1.8rem" } }>Focusing on the challenges of independent merchants and supply chain. Email us at <a href="mailto:hello@behindthebuybutton.com">hello@behindthebuybutton.com</a>.</p> */ }
          <Headings.h3 style={ { marginTop: "40px" } }>What's up with the name?</Headings.h3>
          <p style={ { marginTop: "10px", fontSize: "1.8rem" } }><a href="https://www.linkedin.com/in/jbodenhamer/">Jeremy Bodenhamer</a> coined the term "behind the buy button" in a <i><a href="https://www.dynamo.vc/podcasts/fosc-74-jeremy-bodenhamer-of-shiphawk">podcast interview</a> where he discussed his book <a href="https://smile.amazon.co.uk/Adapt-Die-Survival-Warehouse-Automation/dp/1544517114/ref=sr_1_1?crid=3R8213OUOP5F0">Adapt or Die</a></i>.</p>
          <p style={ { marginTop: "20px", fontSize: "1.7rem" } }><quote><i>"There are a million books about life <b>in front of the buy button (sales and marketing books)</b> and virtually no books on life <b>behind the buy button (operations)</b>. Behind the buy button is where all the growth and competitive advancements are being made today."</i></quote></p>
        </div>
      </Section>
    </Layout >
  );
}

export default About;
